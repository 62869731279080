import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";
import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const isNew = true;
const href = "/ratesheet/20231005-Rate-Sheet.pdf";
const date = "05.10.2023";

const Article = () => (
    <NewsArticleLayout href={href} date={date} isNew={isNew}>
        <h1 className="page-title">Changes to our mortgage products</h1>

        <br />
        <p>With effect from Thursday 5 October 2023 we will be making the following changes to our range:</p>

        <p><strong>Summary of changes</strong></p>
        <p>We have removed our cashback incentive offering across a selection of two and three year fixed rates at 60%, 70% and 75% LTV for UK Residential First Time Buyer and Home Mover customers until further notice. The incentive remains available across higher LTV&rsquo;s for these customers.</p>
        <p><strong>UK Residential Existing Customer Switching Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 85% and 90% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 85% and 90% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential First Time Buyer Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential Home Mover Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential Remortgage Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60% LTV has decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
        </ul>
        <p><strong>UK Residential Remortgage Cashback Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60% and 65% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60% and 65% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Purchase Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
        </ul>
        <p><strong>UK Buy to Let Remortgage Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
        </ul>
        <p><strong>International Residential Range</strong></p>
        <ul>
            <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>3 Year Fixed Standard at 60% LTV has decreased</li>
            <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
            <li>5 Year Premier Exclusive at 60%, 70% and 75% LTV have decreased</li>
        </ul>
        <p>There are no changes to any other interest rates at this time.</p>
        <p>An updated mortgage rate sheet will be published on Thursday 5 October 2023 to reflect these changes.</p>
        <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
    </NewsArticleLayout>
);

export default Article;
